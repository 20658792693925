import React from "react"

import Row from "../../../components/Row/Row"
import PostCard from "../../components/PostCard/PostCard"
import employee_feedback from "@src/icons/generated-use-cases/employee_feedback_header.png"
import promotional_code from "@src/icons/generated-use-cases/promotional_code_manager_header.png"
import sdr_dashboard from "@src/icons/generated-use-cases/sdr_dashboard_header.png"
import user_access_management from "@src/icons/generated-use-cases/user_access_management_header.png"
import bug_reporting_tools from "@src/icons/generated-use-cases/bug_reporting_tools_header.png"
import custom_crm from "@src/icons/generated-use-cases/custom_crm_header.png"
import email_list_manager from "@src/icons/generated-use-cases/email_list_manager_header.png"
import refund_exchange_management from "@src/icons/generated-use-cases/refund-exchange-header.png"
import time_tracker from "@src/icons/generated-use-cases/time_tracker_header.png"

import * as styles from "./Posts.module.scss"

const Posts = () => {
  const posts = [
    {
      feature_image: employee_feedback,
      title: "Revolutionize employee feedback with Canonic",
      url: "/use-cases/employee-feedback-template",
      tags: [{ name: "HR Tool" }],
    },
    {
      feature_image: promotional_code,
      title: "Effortlessly manage promotional codes with Canonic",
      url: "/use-cases/promotional-code-manager",
      tags: [{ name: "Support Tool" }],
    },
    {
      feature_image: sdr_dashboard,
      title: "Gain insights and boost sales with Canonic's SDR dashboard",
      url: "/use-cases/sdr-dashboard",
      tags: [{ name: "Sales Tool" }],
    },
    {
      feature_image: user_access_management,
      title: "Efficient user access management with Canonic",
      url: "/use-cases/user-access-management",
      tags: [{ name: "Admin Panel" }],
    },
    {
      feature_image: bug_reporting_tools,
      title: "Efficient bug reporting with Canonic",
      url: "/use-cases/bug-reporting-tool",
      tags: [{ name: "Developer Tool" }],
    },
    {
      feature_image: custom_crm,
      title: "Unlock the potential of your business with a custom CRM",
      url: "/use-cases/custom-crm",
      tags: [{ name: "CRM" }],
    },
    {
      feature_image: email_list_manager,
      title: "Effortlessly manage email lists with Canonic",
      url: "/use-cases/email-list-manager",
      tags: [{ name: "Marketing Tool" }],
    },
    {
      feature_image: refund_exchange_management,
      title: "Streamline refund & exchange management with Canonic",
      url: "/use-cases/refund-exchange-management",
      tags: [{ name: "Support Tool" }],
    },
    {
      feature_image: time_tracker,
      title: "Boost productivity with Canonic's employee time tracker",
      url: "/use-cases/employee-time-tracker",
      tags: [{ name: "HR Tool" }],
    },
  ]
  return (
    <section>
      <Row className={styles.relatedPostsWrapper}>
        <ul className={`${styles.relatedPosts} ${styles.grid}`}>
          {posts?.map(post => (
            <PostCard post={post} />
          ))}
        </ul>
      </Row>
    </section>
  )
}

export default Posts
