import React from "react"
import Link from "gatsby-plugin-transition-link"

import Layout from "@src/components/Layout"
import Splash from "../Features/components/Splash/Splash"
import Posts from "./sections/Posts/Posts"
import Join from "./sections/Join/Join"
import TemplatesIllustration from "@src/icons/v2/templates-splash.png"
import * as styles from "./Templates.module.scss"

const Templates = () => {
  return (
    <Layout
      title="Templates"
      description="Explore templates for multiple use cases and industries. Clone them to jumpstart on building your internal tool on Canonic."
      url="https://canonic.dev/case-studies"
      //   ogImage={OG}
    >
      <Splash
        title="Build quickly with 
        ready to use Templates"
        subtitle="Explore templates for multiple use cases and industries. Clone them to jumpstart on building your internal tool on Canonic."
        ctas={
          <div className={styles.ctas}>
            <a href="/get-a-demo" rel="noopener noreferrer">
              <button borderless className={styles.ctasButton}>
                Get a Demo
              </button>
            </a>
            <Link
              className={styles.ctasLink}
              to={"/pricing"}
              entry={{ length: 0.3 }}
              exit={{ length: 0.3 }}
            >
              View Pricing Plans
            </Link>
          </div>
        }
        illustration={<img src={TemplatesIllustration} />}
        reverseOnMobile
      />
      <Posts />
      <Join />
    </Layout>
  )
}

export default Templates
